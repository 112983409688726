import React, { PureComponent } from 'react';
import { debounce } from '../../core/utils';
import { isEqual } from 'lodash';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from "./VirtualList.scss";

class VirtualList extends PureComponent {
  // ROW_THRESHOLD specifies when to load more rows. When we scroll to rows.length - ROWS_THRESHOLD it will request more rows (page + 1)
  static defaultProps = {
    ROW_HEIGHT: 48,
    ROW_THRESHOLD: 5,
    handleLoadMore: () => undefined,
    useVirtual: true,
  };

  _prevScroll = 0;
  _wrapper = null;

  state = {
    topMock: 0,
    onScreen: 100,
  };

  isEncounteringThreshold = ({ scrollTop, scrollHeight, clientHeight }) => {
    const { ROW_HEIGHT, ROW_THRESHOLD } = this.props;

    const scrolled = scrollTop + clientHeight;
    const shouldLoad = scrollHeight - scrolled <= ROW_HEIGHT * ROW_THRESHOLD;

    return shouldLoad;
  };

  handleLoadMore = debounce(() => {
    this.props.handleLoadMore();
  }, 50);

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.filters, this.props.filters)) {
      this._wrapper.scrollTop = 0;
    }

    if (!isEqual(prevProps.rows, this.props.rows)) {
      this.handleScroll({ target: this._wrapper });
    }
  }

  componentDidMount() {
    if (window.innerHeight < 800) {
      this.setState({ onScreen: 150 });
    }
  }

  handleScroll = ev => {
    if (this.props.isLoading) return;
    const { scrollTop, scrollHeight, clientHeight } = ev?.target;
    const direction = scrollTop > this._prevScroll ? 'down' : 'up';

    if (direction === 'down' && this.isEncounteringThreshold({ scrollTop, scrollHeight, clientHeight })) {
      this.handleLoadMore();
    }

    this._prevScroll = scrollTop;

    const offsets = this.calculateOffsets({ scrollTop, scrollHeight, clientHeight });
    if (offsets.topMock !== this.state.topMock || offsets.onScreen !== this.state.onScreen) {
      this.setState(offsets);
    }
  };


  assignWrapperRef = el => {
    this._wrapper = el;
    this.props.scrollWrapperRef && this.props.scrollWrapperRef(el);
  };

  renderVirtualScroll = (mergeProps = {}) => {
    const { children, rows } = this.props;
    const { topMock, onScreen } = this.state;
    const slicedRows = rows.slice(topMock, topMock + onScreen);
    const bottomMock = Math.max(0, rows.length - topMock - onScreen);
    return (
      <div>
        <div style={{ height: topMock * this.props.ROW_HEIGHT }} />
        {children({
          topMock,
          bottomMock,
          rows: slicedRows,
          ...mergeProps,
        })}
        <div style={{ height: bottomMock * this.props.ROW_HEIGHT }} />
      </div>
    );
  };

  calculateOffsets = ({ scrollTop, scrollHeight, clientHeight }) => {
    const totalRows = this.props.rows.length;
    const topMock = Math.max(0, Math.floor(scrollTop / this.props.ROW_HEIGHT) - this.props.ROW_THRESHOLD);
    const onScreen = Math.min(Math.floor(clientHeight / this.props.ROW_HEIGHT) + (this.props.ROW_THRESHOLD * 4), totalRows - topMock);

    return { topMock, onScreen };
  };

  render() {
    const { rows, style = {}, className = '', useVirtual = true, children, ...rest } = this.props;

    const content = useVirtual ? this.renderVirtualScroll(rest) : children({ rows, ...rest });

    return (
      <div
        ref={this.assignWrapperRef}
        onScroll={this.handleScroll}
        style={{ overflow: 'auto', height: '100%', ...style }}
        className={cx(styles.virtual_list, className)}
      >
        {content}
      </div>
    );
  }
}

export default withStyles(styles)(VirtualList);